import React from "react";

//Internal Imports
import { useWindowHeight } from "./useWindowHeight";
const links = [
  {
    l: "Website",
    url: "https://bloksapps.com",
    i: "/img/LinkTree/bloksIcon.png"
  },
  {
    l: "Facebook",
    url: "https://facebook.com/bloksapps",
    i: "/img/LinkTree/facebookIcon.png"
  },
  {
    l: "LinkedIn",
    url: "https://www.linkedin.com/company/bloks",
    i: "/img/LinkTree/linkedInIcon.png"
  },
  {
    l: "News",
    url: "https://www.bloksapps.com/news-press",
    i: "/img/LinkTree/newsIcon.png"
  },
  {
    l: "Contact",
    url: "mailto: jared@bloksapps.com",
    i: "/img/LinkTree/contactIcon.png"
  },
  {
    l: "Instagram",
    url: "https://www.instagram.com/bloksapps/",
    i: "/img/LinkTree/instagramIcon.png"
  },
  {
    l: "Blog",
    url: "https://www.bloksapps.com/bloks-blog",
    i: "/img/LinkTree/blogIcon.png"
  },
  {
    l: "Twitter",
    url: "https://twitter.com/bloksapps",
    i: "/img/LinkTree/twitterIcon.png"
  }
];

document.body.style.overflow = "hidden";

const LinkTreePage = () => {
  const height = useWindowHeight();
  return (
    <div
      style={{
        height: height,
        backgroundImage: 'url("/img/Backgrounds/linktreeBg.png")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        paddingTop: 36,
        display: "flex",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          maxWidth: 400,
          flexWrap: "wrap",
          height: 100,
          display: "flex",
          paddingLeft: 14,
          paddingRight: 14
        }}
      >
        {links.map((l, i) => (
          <a
            key={i}
            href={l.url}
            target="blank"
            style={{
              textAlign: "center",
              color: "white",
              fontWeight: 500,
              cursor: "default",
              width: "25%",
              textDecoration: "none"
            }}
          >
            <img
              alt=""
              src={l.i}
              style={{
                width: "100%",
                marginBottom: 2,
                filter: "drop-shadow(2px 2px 4px rgba(0,0,0,.2))",
                borderRadius: 17
              }}
            />
            <div style={{ marginTop: -30, marginBottom: 10 }}>{l.l}</div>
          </a>
        ))}
      </div>
      <div style={style.labelWrap} className="pl-3 shadow">
        <img
          alt=""
          src="/img/LinkTree/linktreeLogo.png"
          style={{ width: 67 }}
        />
        <div style={{ lineHeight: 1, paddingTop: 4 }}>
          Make Your Own Mobile App
        </div>
      </div>
    </div>
  );
};

export default LinkTreePage;

const style = {
  labelWrap: {
    position: "absolute",
    bottom: 52,
    height: 72,
    background: "rgba(255,255,255,.5)",
    borderRadius: 10,
    left: "50%",
    width: "84%",
    transform: "translate(-50%, 0%)",
    maxWidth: 370,
    color: "black",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 24,
    boxShadow: ".5rem .5rem .75rem rgba(0,0,0,.18)"
    // paddingBottom: 6,
  }
};
